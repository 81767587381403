import React from 'react';
import Sectitle from "../../Title/Sectitle";
import Tabitem from "./CloudServiceTabItem";

const CloudServiceTab =()=>{
    return(
        <section className="software_service_area sec_pad">
            <div className="container">
                <Sectitle Title="Seamlessly go from deploying to scaling." TitleP="Tickety-boo cheers plastered twit chimney pot off his nut hotpot spend a penny have it, so I said squiffy A bit of how's your father."
                 sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        <ul className="nav nav-tabs software_service_tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="de-tab" data-toggle="tab" href="#de" role="tab" aria-controls="de" aria-selected="true">Extended Wi-Fi Coverage</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="saas-tab" data-toggle="tab" href="#saas" role="tab" aria-controls="saas" aria-selected="false">Wi-Fi Performance</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="ma-tab" data-toggle="tab" href="#ma" role="tab" aria-controls="ma" aria-selected="true">Scalability</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="secure-tab" data-toggle="tab" href="#secure" role="tab" aria-controls="secure" aria-selected="false">Network Security</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="scale-tab" data-toggle="tab" href="#scale" role="tab" aria-controls="scale" aria-selected="true">Multiple SSIDs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="monitor-tab" data-toggle="tab" href="#monitor" role="tab" aria-controls="monitor" aria-selected="false">Intuitive User Interface</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <div className="tab-content software_service_tab_content">
                            <div className="tab-pane fade show active" id="de" role="tabpanel" aria-labelledby="de-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-rss-alt" text="Better Connectivity" description="With extended wifi coverage, you can enjoy better connectivity throughout your home or office. You can connect more devices, and the signal strength and speed of your internet will be stronger"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-stats-up" text="Increased Productivity" description="With a stronger wifi signal, you can work more efficiently, stream videos without buffering, and have faster downloads. This can increase productivity and save time."/>
                                    <Tabitem ticon="ti-youtube" text="Improved Entertainment Experience" description="With extended wifi coverage, you can enjoy seamless streaming of your favorite TV shows and movies without interruption. You can also play online games without any lag or buffering"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-home" text="Better Home Automation" description="Extended wifi coverage can enhance your home automation experience, allowing you to control your smart devices from anywhere in your home. You can control your lights, thermostat, and security system with ease, making your home more comfortable and secure"/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="saas" role="tabpanel" aria-labelledby="saas-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-bolt" text="Faster Internet Speeds" description="One of the primary benefits of improved wifi performance is faster internet speeds. This means that you can download files, stream videos, and browse the web more quickly"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-signal" text="Better Signal Strength" description="Improved wifi performance also means better signal strength. This means that you can enjoy a more reliable connection and fewer dropped signals"/>
                                    <Tabitem ticon="ti-fullscreen" text="Increased Range" description="With improved wifi performance, you can also enjoy increased range. This means that you can connect to your wifi network from further away, allowing you to work or play from anywhere in your home or office"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-shield" text="Improved Security" description="Improved wifi performance can also improve the security of your network. With a stronger signal, you can ensure that your network is more secure and less susceptible to unauthorized access or hacking attempts"/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ma" role="tabpanel" aria-labelledby="ma-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-upload" text="Flexible Growth" description="Scalability in wifi allows for flexible growth, which means that you can easily expand your network as your needs grow. This means that you don't have to replace your entire network if you need more coverage, which can save you time and money"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-money" text="Cost Savings" description="By implementing a scalable wifi solution, you can save money on infrastructure costs. You can start with a smaller network and gradually add on to it as needed, which means that you can avoid overspending on unnecessary hardware"/>
                                    <Tabitem ticon="ti-desktop" text="Improved Performance" description="Scalability in wifi can also improve performance. By adding more access points to your network, you can ensure that your devices have a stronger signal and faster speeds, which can enhance your overall wifi experience"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-tablet" text="Increased Reliability" description="Scalability in wifi can also improve network reliability. By adding redundancy to your network, you can ensure that your wifi stays up and running even if one or more access points fail. This can reduce downtime and ensure that your network stays connected"/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="secure" role="tabpanel" aria-labelledby="secure-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-cloud" text="Cybersecurity Protection" description="Network security measures such as firewalls, antivirus software, and intrusion detection systems help protect your network from cyber threats such as viruses, malware, and hacking attempts. This helps keep your data and sensitive information safe"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-thumb-up" text="Improved Reliability" description="A secure network is less likely to experience downtime due to cyber attacks or other security issues. This means that you can rely on your network to stay up and running when you need it"/>
                                    <Tabitem ticon="ti-bar-chart" text="Enhanced Productivity" description="Network security can also enhance productivity by reducing the risk of data breaches and other security incidents that could disrupt your workflow. This means that you can focus on your work without worrying about the security of your network"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-dashboard" text="Regulatory Compliance" description="Many industries are subject to regulations regarding data security and privacy. Network security measures can help ensure that your network is in compliance with these regulations, avoiding potential legal and financial consequences"/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="scale" role="tabpanel" aria-labelledby="scale-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-view-list" text="Network Segmentation" description="By using multiple SSIDs, you can segment your network into different virtual networks, each with their own security settings, access permissions, and bandwidth allocation. This can help improve network security, optimize network performance, and provide a better user experience"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-user" text="Guest Access" description="Multiple SSIDs can also be used to provide guest access to your wifi network without compromising the security of your main network. You can set up a separate SSID for guests with limited access permissions and bandwidth allocation, which can help prevent unauthorized access to your main network"/>
                                    <Tabitem ticon="ti-mobile" text="Device Management" description="Multiple SSIDs can also help you manage your devices more efficiently. You can assign different SSIDs to different types of devices or users, making it easier to identify and troubleshoot any issues that may arise"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-control-shuffle" text="Flexibility" description="Multiple SSIDs provide more flexibility when it comes to network management. You can change the settings for each SSID independently, allowing you to tailor the network to the specific needs of different users or devices"/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="monitor" role="tabpanel" aria-labelledby="monitor-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-comments-smiley" text="User Friendly" description="An intuitive user interface makes it easy to navigate and use the wifi dashboard. This means that you can quickly and easily access the information you need, without having to spend time learning complex menus or commands"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-bolt-alt" text="Increased Efficiency" description="An intuitive user interface can increase efficiency by reducing the time and effort required to manage your network. You can perform tasks quickly and easily, allowing you to focus on other important tasks"/>
                                    <Tabitem ticon="ti-info-alt" text="Improved Visibility" description="An intuitive user interface provides improved visibility into your network. You can easily monitor network performance, track usage patterns, and identify any issues that may arise"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-panel" text="Enhanced Customization" description="An intuitive user interface can also enhance customization options, allowing you to tailor the dashboard to your specific needs. You can customize the layout, add or remove widgets, and adjust the settings to suit your preferences. This can help you manage your network more effectively and efficiently"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CloudServiceTab;