import React, { Component } from 'react';
import CloudServiceItem from './CloudServiceItem';

class CloudService extends Component{
    render(){
        return(
            <section className="software_featured_area">
                <div className="container">
                    <h2 className="f_600 f_size_30 t_color3 text-center l_height40 mb_70 wow fadeInUp" data-wow-delay="0.3s">Stay connected, Stay ahead with Smart Wifi <br/>Your gateway to seamless internet experience</h2>
                    <div className="row software_featured_info">
                        <CloudServiceItem icon="icon1.png" sTitle="Improved Productivity" Sp="Faster internet boosts productivity through quick access to resources, fast file transfers, and seamless communication, enhancing overall workflow and efficiency"/>
                        <CloudServiceItem icon="icon2.png" sTitle="Enhanced Multimedia Experience" Sp="Enjoy an immersive multimedia experience with high-definition streaming, lag-free video conferences, and smooth online gaming, maximizing your entertainment and communication possibilities"/>
                        <CloudServiceItem icon="icon3.png" sTitle="Efficient Data Transfer" Sp="Enjoy an immersive multimedia experience with high-definition streaming, lag-free video conferences, and smooth online gaming, maximizing your entertainment and communication possibilities"/>
                    </div>
                </div>
            </section>
        )
    }
}
export default CloudService;