import React from 'react';

const FaqSection =()=>{
    return(
        <section className="faq_area bg_color sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 pr_50">
                        <div className="faq_tab">
                            <h4 className="f_p t_color3 f_600 f_size_22 mb_40">FAQ Navigation</h4>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="purchas-tab" data-toggle="tab" href="#purchas" role="tab" aria-controls="purchas" aria-selected="true">General</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="returns-tab" data-toggle="tab" href="#returns" role="tab" aria-controls="returns" aria-selected="false">Returns</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="price-tab" data-toggle="tab" href="#price" role="tab" aria-controls="price" aria-selected="false">Product & Support</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="care-tab" data-toggle="tab" href="#care" role="tab" aria-controls="care" aria-selected="false">Warranty</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="tab-content faq_content" id="myTabContent">
                            <div className="tab-pane fade show active" id="purchas" role="tabpanel" aria-labelledby="purchas-tab">
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20">General</h3>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    What is a Smart Home?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body">
                                                A Smart Home is a residence equipped with devices and systems that can be controlled remotely, automated, and connected to a network for
                                                improved comfort, convenience, energy efficiency, and security.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    What devices can be included in a Smart Home?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                A Smart Home can include a wide range of devices such as smart thermostats, lighting systems, security cameras,
                                                smart locks, voice assistants, smart appliances, and more. These devices can be controlled and monitored through a centralized hub or mobile app.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    How does a Smart Home benefit me?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div className="card-body">
                                                Smart Homes offer numerous benefits, including enhanced convenience through automation and remote control, improved energy efficiency by optimizing energy usage,
                                                increased security with real-time monitoring and alerts, and added comfort through personalized settings and smart climate control.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                    Is it complicated to set up and manage a Smart Home?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                            <div className="card-body">
                                                Setting up and managing a Smart Home can be made simple with user-friendly interfaces and intuitive mobile apps. Many devices are designed for easy installation and can be seamlessly integrated into your existing home infrastructure.
                                                Additionally, our team is available to provide assistance and guidance throughout the process.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                                    Can I control my Smart Home remotely?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefive" className="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                                            <div className="card-body">
                                                Yes! With a Smart Home, you can control and monitor your devices remotely from anywhere using a smartphone or tablet. Whether you want to adjust the temperature, turn off lights,
                                                or check your security cameras, you have full control at your fingertips.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingsix">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                                    Will a Smart Home work in my existing home?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsesix" className="collapse" aria-labelledby="headingsix" data-parent="#accordion">
                                            <div className="card-body">
                                                Yes, a Smart Home can be installed and integrated into most existing homes. Whether you have a small apartment or a larger house,
                                                there are flexible solutions available to suit your specific requirements.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingseven">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                                    Are Smart Homes energy-efficient?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseseven" className="collapse" aria-labelledby="headingseven" data-parent="#accordion">
                                            <div className="card-body">
                                                Yes, Smart Homes promote energy efficiency by allowing you to control and optimize energy consumption. You can schedule devices, monitor energy usage,
                                                and receive insights to make informed decisions about energy conservation.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="f_p f_size_22 f_500 t_color3 mb_20 mt_100">Installation</h3>
                                <div id="accordion2">
                                    <div className="card">
                                        <div className="card-header" id="headingeight">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                                    Do I Need Neutral Wire For Smart Switches?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseeight" className="collapse" aria-labelledby="headingeight" data-parent="#accordion2">
                                            <div className="card-body">
                                                Preferably, Yes. Most smart switches require a neutral wire to function properly, but some models offer alternatives. Check product documentation or seek professional advice for compatibility.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingnine">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                                    If I already have a contractor working on my renovation, Can i still engage Intellihaus<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsenine" className="collapse" aria-labelledby="headingnine" data-parent="#accordion2">
                                            <div className="card-body">
                                                Yes! We encourage our customer to speak to us as soon as possible, We will liaise with your contractor and work with them.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingten">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                                                    How long does it take to install the devices?<i className="ti-plus"></i><i className="ti-minus"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseten" className="collapse" aria-labelledby="headingten" data-parent="#accordion2">
                                            <div className="card-body">
                                                The installation and configuration process typically takes 1-2 days to complete, although the duration may vary depending on the unit's area and the progress of contractor renovations.                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="tab-pane fade" id="returns" role="tabpanel" aria-labelledby="returns-tab">*/}
                            {/*    <h3 className="f_p f_size_22 f_500 t_color3 mb_20">How to purchase</h3>*/}
                            {/*    <div id="accordion3">*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading10">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapse10" aria-expanded="true" aria-controls="collapse10">*/}
                            {/*                        How do I repair an item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}

                            {/*            <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading11">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">*/}
                            {/*                        Where can I find instructions on how to use my watch?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse11" className="collapse" aria-labelledby="heading11" data-parent="#accordion3">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading12">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapse12">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse12" className="collapse" aria-labelledby="heading12" data-parent="#accordion3">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading13">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse13" aria-expanded="false" aria-controls="collapse13">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse13" className="collapse" aria-labelledby="heading13" data-parent="#accordion3">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading14">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse14" aria-expanded="false" aria-controls="collapse14">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse14" className="collapse" aria-labelledby="heading14" data-parent="#accordion3">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="tab-pane fade" id="price" role="tabpanel" aria-labelledby="price-tab">*/}
                            {/*    <h3 className="f_p f_size_22 f_500 t_color3 mb_20">How to purchase</h3>*/}
                            {/*    <div id="accordion4">*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading15">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapse15" aria-expanded="true" aria-controls="collapse15">*/}
                            {/*                        How do I repair an item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}

                            {/*            <div id="collapse15" className="collapse show" aria-labelledby="heading15" data-parent="#accordion4">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading16">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse16" aria-expanded="false" aria-controls="collapse16">*/}
                            {/*                        Where can I find instructions on how to use my watch?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse16" className="collapse" aria-labelledby="heading16" data-parent="#accordion4">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading17">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse17" aria-expanded="false" aria-controls="collapse17">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse17" className="collapse" aria-labelledby="heading17" data-parent="#accordion4">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading18">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse18" aria-expanded="false" aria-controls="collapse18">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse18" className="collapse" aria-labelledby="heading18" data-parent="#accordion4">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading19">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse19" aria-expanded="false" aria-controls="collapse19">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse19" className="collapse" aria-labelledby="heading19" data-parent="#accordion4">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <h3 className="f_p f_size_22 f_500 t_color3 mb_20 mt_100">How to pay</h3>*/}
                            {/*    <div id="accordion5">*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading20">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse20" aria-expanded="false" aria-controls="collapse20">*/}
                            {/*                        How do I repair an item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse20" className="collapse" aria-labelledby="heading20" data-parent="#accordion5">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading21">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse21" aria-expanded="false" aria-controls="collapse21">*/}
                            {/*                        Where can I find instructions on how to use my watch?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse21" className="collapse" aria-labelledby="heading21" data-parent="#accordion5">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading22">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse22" aria-expanded="false" aria-controls="collapse22">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse22" className="collapse" aria-labelledby="heading22" data-parent="#accordion5">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading23">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse23" aria-expanded="false" aria-controls="collapse23">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse23" className="collapse" aria-labelledby="heading23" data-parent="#accordion5">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="tab-pane fade" id="care" role="tabpanel" aria-labelledby="care-tab">*/}
                            {/*    <h3 className="f_p f_size_22 f_500 t_color3 mb_20">How to purchase</h3>*/}
                            {/*    <div id="accordion6">*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading24">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapse24" aria-expanded="true" aria-controls="collapse24">*/}
                            {/*                        How do I repair an item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}

                            {/*            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading25">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse25" aria-expanded="false" aria-controls="collapse25">*/}
                            {/*                        Where can I find instructions on how to use my watch?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse25" className="collapse" aria-labelledby="heading25" data-parent="#accordion6">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading26">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse26" aria-expanded="false" aria-controls="collapse26">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse26" className="collapse" aria-labelledby="heading26" data-parent="#accordion6">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading27">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse27" aria-expanded="false" aria-controls="collapse27">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse27" className="collapse" aria-labelledby="heading27" data-parent="#accordion6">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="card">*/}
                            {/*            <div className="card-header" id="heading28">*/}
                            {/*                <h5 className="mb-0">*/}
                            {/*                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse28" aria-expanded="false" aria-controls="collapse28">*/}
                            {/*                        Is there a warranty on my item?<i className="ti-plus"></i><i className="ti-minus"></i>*/}
                            {/*                    </button>*/}
                            {/*                </h5>*/}
                            {/*            </div>*/}
                            {/*            <div id="collapse28" className="collapse" aria-labelledby="heading28" data-parent="#accordion6">*/}
                            {/*                <div className="card-body">*/}
                            {/*                    Why I say old chap that is spiffing pukka, bamboozled wind up bugger buggered zonked hanky panky a blinding shot the little rotter, bubble and squeak vagabond cheeky bugger at public school pardon you bloke the BBC. Tickety-boo Elizabeth plastered matie.!*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FaqSection;