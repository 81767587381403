import React, {Component} from 'react';

class SecurityPrice extends Component {
    constructor(props){
        super(props);
        this.state= {
            list: [
                {
                    id: 1,
                    adTitle: "Professional Planning",
                    text: 'Included',
                    text2: 'Included',
                    text3: 'Included',
                    text4: 'Included',
                    text5: 'Included',

                },
                {
                    id: 2,
                    adTitle: "Light Switch",
                    text: '4 Switches',
                    text2: '5 Switches',
                    text3: '6 Switches',
                    text4: '9 Switches',
                    text5: 'On Request',
                },
                {
                    id: 3,
                    adTitle: "Control Hub",
                    text: '1 Hubs',
                    text2: '1 Hubs',
                    text3: '1 Hubs',
                    text4: '3 Hubs',
                    text5: 'On Request',
                },
                {
                    id: 4,
                    adTitle: "Infrared Controller",
                    text: '2 Controller',
                    text2: '4 Controller',
                    text3: '5 Controller',
                    text4: '8 Controller',
                    text5: 'On Request',
                },
                {
                    id: 5,
                    adTitle: "Wi-Fi Access Point",
                    text: 'Not Included',
                    text2: '2 Access Point',
                    text3: '2 Access point',
                    text4: '3 Access Point',
                    text5: 'On Request',


                },
                {
                    id: 6,
                    adTitle: "Configuration",
                    text: 'Included',
                    text2: 'Included',
                    text3: 'Included',
                    text4: 'Included',
                    text5: 'Included',
                },
                {
                    id: 7,
                    adTitle: "Installation",
                    text: 'Included',
                    text2: 'Included',
                    text3: 'Included',
                    text4: 'Included',
                    text5: 'Included',
                },
                {
                    id: 8,
                    adTitle: "On-Site Demo",
                    text: 'Included',
                    text2: 'Included',
                    text3: 'Included',
                    text4: 'Included',
                    text5: 'Included',
                }
            ]
        }
    }
    render(){
        return(
            <section className="pricing_area_four sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Smart Home Package</span> Free Planning and Expert Advice</h2>
                </div>
                <div className="price_info_two price_info_three">
                    <div className="price_head">
                        <div className="p_head time">
                            <h4><b>Smart Home Package</b></h4>
                        </div>
                        <div className="p_head">
                            <h5>Basic Package</h5>
                            <p>SGD $1500</p>
                        </div>
                        <div className="p_head">
                            <h5>Essential Package</h5>
                            <p>SGD $2300</p>
                        </div>
                        <div className="p_head">
                            <h5>Complete Package</h5>
                            <p>SGD $2400</p>
                        </div>
                        <div className="p_head">
                            <h5>Multi-Storey Package</h5>
                            <p>SGD $5200</p>
                        </div>
                        <div className="p_head">
                            <h5>Customization Package</h5>
                            <p>Price Varies</p>
                        </div>
                    </div>
                    <div className="price_body">
                        {
                            this.state.list.map( list => {
                                return(
                                    <div className="pr_list" key={list.id}>
                                        <div className="price_item">
                                        <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" data-original-title="Item List">{list.adTitle}</h5>
                                        </div>
                                        <div className="price_item" data-title="Basic">
                                            <h5 className="check">{list.text}</h5>
                                        </div>
                                        <div className="price_item" data-title="Essential">
                                            <h5 className="check">{list.text2}</h5>
                                        </div>
                                        <div className="price_item" data-title="Complete">
                                            <h5 className="check">{list.text3}</h5>
                                        </div>
                                        <div className="price_item" data-title="Multi-Storey">
                                            <h5 className="check">{list.text4}</h5>
                                        </div>
                                        <div className="price_item" data-title="Customization">
                                            <h5 className="check">{list.text5}</h5>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="pr_list">
                            <div className="price_item">
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
export default SecurityPrice;